type RowContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const RowContainer = ({
  children, 
  className,
}: RowContainerProps) => {
  const css = className ?? "";
  return (
    <div className={`${css} flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start w-full`}>
      {children}
    </div>
  );
}

export default RowContainer;