import { Navigate } from "react-router-dom";
import { ConstantsRoutes } from '../../pages/routes/constants-routes';
import { useStorage } from '../../providers/storage-provider';

type ProtectedPasswordProps = {
  children: React.ReactNode;
};

const ProtectedPassword = ({
  children
}:ProtectedPasswordProps) => {

  const { user } = useStorage();

  return (
    <>
      {
        !user.actualizado && (
          <Navigate to={ConstantsRoutes.UPDATE_DATA} />
        )
      }
      { 
        user.actualizado && (
          children 
        )
      }
    </>
  )
};

export default ProtectedPassword;