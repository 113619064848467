import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
import 'react-floating-whatsapp-button/dist/index.css';
import Container from '../../generic/grid/container';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';

type ClientsLayoutProps = {
  children: React.ReactNode;
};

const ClientsLayout = ({
  children
}: ClientsLayoutProps) => {

  return (
    <div className='max-w-screen-2xl mx-auto'>
      <div className='font-display min-h-screen text-secondary-500 overflow-hidden'>
        <Navbar />
        <Container>
          <div className='flex items-center justify-center'>
            {children}
          </div>
        </Container>
        <Footer />
        <FloatingWhatsApp 
          phone={"527711861415"}
          message={"Quiero realizar un pago de mi crédito."}
          headerTitle={"Point.mx"}
          popupMessage={"¿Tienes alguna duda?"}
          zIndex={30}
        />
      </div>
    </div>
  );
};

export default ClientsLayout;