import { useEffect, useRef, useState } from 'react';
import { BusinessCreditModel } from '../../model/credits/credits-model';
import ColumnContainer from "../generic/grid/column-container";
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import BoldText from '../generic/text/bold-text';
import LightText from "../generic/text/light-text";

type CreditPercentageProps = {
  credit: BusinessCreditModel;
  contractNumber: string;
  isSelected: boolean;
  onClick: (contractNumber: string) => void;
};

const CreditPercentage = ({
  contractNumber,
  credit, 
  isSelected,
  onClick
}: CreditPercentageProps) => {
  
  const [progress, setProgress] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  const activeBorder = isSelected ? "border-purple-primary" : "border-gray-soft-hover";
  const activeBg = isSelected ? "bg-purple-primary" : "bg-gray-hard";

  useEffect(() => {
    let progress = 0;

    const totalToBePaid = credit.numPagos * credit.cuotaRecurrente;
    const totalPaid = totalToBePaid - credit.ultCuota.saldoLiquidar;
    let progressCalculated = Math.floor(
      ( totalPaid * 100 ) / totalToBePaid
    );
    
    if(progressCalculated > 100) progressCalculated = 100;
    setProgress(progressCalculated);

    let interval = setInterval(() => {
      progress = progress + 2;
      if(ref.current) ref.current.style.width = `${progress}%`
      if(progress >= progressCalculated) {
        clearInterval(interval)
      }
    }, 20);
  }, [credit] );

  return (
    <div
      key={credit.numContrato}
      className={`${activeBorder} cursor-pointer border-2 rounded-md p-2 my-2 group hover:border-blue-soft `}
      onClick={() => onClick(credit.numContrato)}
    >
      <ColumnContainer>
        <LightText>
          {credit.numContrato}
        </LightText>
        <SpaceBetweenContainer>
          {/* Left */}
          <div className="w-full h-6 bg-gray-soft rounded-lg">
            <div 
              key={contractNumber}
              ref={ref}
              className={`${activeBg} h-6 group-hover:bg-blue-soft rounded-lg`} 
            />
          </div>
          {/* Right */}
          <div className='min-w-[125px] px-4'>
            <BoldText>
            {
              progress === 100 ? `¡Pagado!` : `${progress}%`
            }
            </BoldText>
          </div>
        </SpaceBetweenContainer>
      </ColumnContainer>
    </div>
  );
}

export default CreditPercentage;