import HttpsIcon from '@mui/icons-material/Https';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { UpdatePasswordModel } from '../../model/users/user-model';
import { ConstantsRoutes } from '../../pages/routes/constants-routes';
import { useApis } from '../../providers/apis-provider';
import { useStorage } from '../../providers/storage-provider';
import { updatePassword } from '../../services/users-service';
import { encodeStringToBase64 } from '../../utils/encoders';
import RoundedButton from "../generic/buttons/rounded-button";
import Container from "../generic/grid/container";
import LabelValidation from "../generic/grid/label-validation";
import IconInput from "../generic/inputs/icon-input";
import RoundedRectangle from '../generic/shapes/rounded-rectangle';


const UpdatePasswordForm = () => {

  const [oldpasswordHide, isOldPasswordHide] = useState<boolean>(true);
  const [newpasswordHide, isNewPasswordHide] = useState<boolean>(true);
  const [newpasswordConfirmHide, isNewPasswordConfirmHide] = useState<boolean>(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser } = useStorage();
  const { newRequest } = useApis();

  // FIXME: Tipar formData
  const handleSubmit = (formData: any) => {
    formData.preventDefault();
    formValidation.handleSubmit();
    return false;
  }

  const formValidation = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Ingresa tu contraseña anterior"),
      newPassword: Yup.string().required("Ingresa tu nueva contraseña"),
      newPasswordConfirm: Yup.string()
        .required("Confirma tu nueva contraseña")
        .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden'),
    }),
    onSubmit: (values) => {
      // Body of the request
      const passwordUpdated: UpdatePasswordModel = {
        codigoUsuario: user.user,
        passwordAnterior: encodeStringToBase64(values.oldPassword),
        passwordNuevo: encodeStringToBase64(values.newPassword),
        usuarioUltMod: user.userId,
      }
      // Generate request
      newRequest(
        updatePassword(passwordUpdated)
      )
      .then(() => {
        enqueueSnackbar(
          "Se actualizo la información correctamente", 
          {  variant: 'success' },
        );
        setUser(({
          ...user,
          actualizado: true
        }));
        navigate(ConstantsRoutes.HOME);
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response?.data?.detalles[0]?.mensaje ?? "No se puedo actualizar la contraseña", 
          {  variant: 'error' },
        );
      });
    }
  });

  return (
    <React.Fragment>
      <RoundedRectangle
        className='!bg-gray-soft'
      >
        <Container>
          <div className='py-4'>
            <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-hard font-extrabold uppercase">
              Actualizar contraseña
            </h2>
          </div>

          <form
            id="update-password-form"
            onSubmit={(e) => handleSubmit(e)}
            className='py-4'
          >

            <div className="mb-3">
              <div className="input-group auth-pass-inputgroup">
                <IconInput
                  label="Contraseña anterior"
                  icon={
                    // FIXME: Color hardcoded
                    oldpasswordHide
                      ? <HttpsIcon style={{ color: "#8d43ed" }} />
                      : <VisibilityIcon style={{ color: "#8d43ed" }} />
                  }
                  name="oldPassword"
                  type={oldpasswordHide ? "password" : "text"}
                  autoComplete="true"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.oldPassword || ""}
                  onClick={() => isOldPasswordHide(!oldpasswordHide)}
                >
                  {
                    formValidation.touched.oldPassword && formValidation.errors.oldPassword && (
                      <LabelValidation className=" !text-sm md:!text-sm ">
                        {formValidation.errors.oldPassword}
                      </LabelValidation>
                    )
                  }
                </IconInput>
              </div>
            </div>

            <div className="mb-3">
              <div className="input-group auth-pass-inputgroup">
                <IconInput
                  label="Nueva contraseña"
                  icon={
                    // FIXME: Color hardcoded
                    newpasswordHide
                      ? <HttpsIcon style={{ color: "#8d43ed" }} />
                      : <VisibilityIcon style={{ color: "#8d43ed" }} />
                  }
                  name="newPassword"
                  type={newpasswordHide ? "password" : "text"}
                  autoComplete="true"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.newPassword || ""}
                  onClick={() => isNewPasswordHide(!newpasswordHide)}
                >
                  {
                    formValidation.touched.newPassword && formValidation.errors.newPassword && (
                      <LabelValidation className=" !text-sm md:!text-sm ">
                        {formValidation.errors.newPassword}
                      </LabelValidation>
                    )
                  }
                </IconInput>
              </div>
            </div>

            <div className="mb-3">
              <div className="input-group auth-pass-inputgroup">
                <IconInput
                  label="Confirmar nueva contraseña"
                  icon={
                    // FIXME: Color hardcoded
                    newpasswordConfirmHide
                      ? <HttpsIcon style={{ color: "#8d43ed" }} />
                      : <VisibilityIcon style={{ color: "#8d43ed" }} />
                  }
                  name="newPasswordConfirm"
                  type={newpasswordConfirmHide ? "password" : "text"}
                  autoComplete="true"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.newPasswordConfirm || ""}
                  onClick={() => isNewPasswordConfirmHide(!newpasswordConfirmHide)}
                >
                  {
                    formValidation.touched.newPasswordConfirm && formValidation.errors.newPasswordConfirm && (
                      <LabelValidation className=" !text-sm md:!text-sm ">
                        {formValidation.errors.newPasswordConfirm}
                      </LabelValidation>
                    )
                  }
                </IconInput>
              </div>
            </div>

            <div className="mt-5 d-grid">
              <div className='flex items-center justify-center'>
                <RoundedButton
                  className="w-1/2"
                  type="submit"
                >
                  Actualizar
                </RoundedButton>
              </div>
            </div>
          </form>
        </Container>
      </RoundedRectangle>
    </React.Fragment>
  );
}

export default UpdatePasswordForm;