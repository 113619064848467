import { useEffect } from 'react';
import Login from '../components/form/login';
import RoundedRectangle from '../components/generic/shapes/rounded-rectangle';

const LoginPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
    <div className='h-48'/>
    <div className='flex flex-col lg:flex-row justify-center items-center min-h-max pb-28'>
      <RoundedRectangle
        className='h-[40vh] lg:h-[60vh] w-4/5 lg:w-2/5 bg-login-background bg-[length:70%] md:bg-[length:40%] lg:bg-[length:60%] bg-no-repeat bg-bottom'
      />
      <RoundedRectangle
        className='!bg-gray-soft h-[40vh] lg:h-[60vh] w-4/5 lg:w-2/5 lg:ml-[-50px]'
      >
        <Login />
      </RoundedRectangle>
    </div>
    </>
  );
};

export default LoginPage;