type TextHeaderProps = {
  children: React.ReactNode
  className?: string
}

const TextHeader = ({ children, className }: TextHeaderProps) => {
  const css = className ?? ''
  return (
    <h1
      className={`text-gray-hard font-lilita text-4xl md:text-5xl lg:text-6xl leading-none max-w-3xl break-words ${css} `}
    >
      {children}
    </h1>
  )
}

export default TextHeader
