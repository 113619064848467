import { ApiModel } from "../model/apis/apis-model";
import { Config } from '../utils/environment-constants';

const CLIENTS_PATH = '/clientes/v1';

export const mapGetUserInfo = (
  userCode: String
):ApiModel => ({
  endpoint: `${Config.API.URL}${CLIENTS_PATH}/portal/cliente?codigoUsuario=${userCode}`,
  verb: "GET"
});