import { Navigate } from "react-router-dom";
import { ConstantsRoutes } from '../../pages/routes/constants-routes';
import { useStorage } from '../../providers/storage-provider';
import { notNullUndefined } from '../../utils/validations';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

const ProtectedRoute = ({
  children
}:ProtectedRouteProps) => {

  const { token, user } = useStorage();

  if( notNullUndefined(token) && notNullUndefined(user) 
    && token.accessToken !== "" && user.userId !== 0 
  ) {
    return ( 
      <>
      { children }
      </>
    )
  }
  
  return (
    <>
    <Navigate to={ConstantsRoutes.LOGIN} />
    </>
  )
};

export default ProtectedRoute;