import Footer from '../footer/footer';
import HeaderComponent from '../header/header-component';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({
  children
}: LayoutProps) => {
  return (
    <>
      <div className='max-w-screen-2xl mx-auto'>
        <div className='font-display min-h-screen text-secondary-500 overflow-hidden'>    
          <HeaderComponent/>
          {children}
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default Layout;