type LightTextProps = {
  children: React.ReactNode;
  className?: string;
};

const LightText = ({
  children, 
  className,
}: LightTextProps) => {
  const css = className ?? "";
  return (
    <span 
      className={`${css} text-gray-hard text-base md:text-lg lg:text-xl font-barlow`}
    >
      {children}
    </span>
  );
}

export default LightText;