import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClockLoader } from "react-spinners";
import * as Yup from "yup";
import { TokenModel } from '../../model/auth/token-model';
import { RoleModel } from '../../model/users/user-model';
import { useStorage } from '../../providers/storage-provider';
import { authUserRequest } from '../../services/auth-service';
import { getUserRequestLogin } from '../../services/users-service';
import { ROLE_CLIENT } from '../../utils/constants';
import Alert from "../generic/alerts/alert";
import RoundedButton from "../generic/buttons/rounded-button";
import Container from "../generic/grid/container";
import LabelValidation from "../generic/grid/label-validation";
import IconInput from "../generic/inputs/icon-input";

type AuthErrorType = {
  title: string;
  description: string;
  error: boolean;
}

const Login = () => {

  const [authError, setAuthError] = useState<AuthErrorType>(emptyAuthErrorType);
  const [passwordHide, isPasswordHide] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  const { setUser, setToken } = useStorage();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // FIXME: Tipar formData
  const handleSubmit = (formData: any) => {
    formData.preventDefault();
    formValidation.handleSubmit();
    return false;
  }

  const handlePasswordVisibility = () => {
    isPasswordHide(!passwordHide);
  }

  const formValidation = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Ingresa tu nombre de usuario"),
      password: Yup.string().required("Ingresa tu contraseña"),
    }),
    onSubmit: (values) => {
      setLoader(true);
      authUserRequest({
        username: values.user,
        password: values.password
      })
      .then(response => {
        const token: TokenModel = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          user: values.user,
        }
        
        // Get user information request
        getUserRequestLogin(
          token,
          values.user,
        )
        .then(userResponse => {
          // Validate has ROLE_CLIENT
          const roles: RoleModel[] = userResponse.data.resultado.roles;
          if(!roles.find(rol => rol.nombre === ROLE_CLIENT)) throw Error("Rol not found")
          
          const user = {
            user: userResponse.data.resultado.codigoUsuario,
            userId: userResponse.data.resultado.idUsuario,
            name: `${userResponse.data.resultado.persona.nombre} ${userResponse.data.resultado.persona.apellidoPaterno}`,
            bloqueo: userResponse.data.resultado.bloqueo,
            actualizado: userResponse.data.resultado.actualizado,
          };
          
          setUser(user);
          setToken(token);
          enqueueSnackbar(
            `Bienvenido: ${user.name}`,
            { variant: 'default' }
          );
          navigate("/", { replace: true });
          setAuthError(emptyAuthErrorType);
        })
        .catch(() => 
          setAuthError((prev) => ({
            ...prev,
            error: true
          })) 
        )
      })
      .catch((error) => 
        setAuthError((prev) => ({
          title: prev.title,
          description: error.response?.data?.detalles[0]?.mensaje ?? prev.description,
          error: true
        })) 
      )
      .finally(() => setLoader(false));
    }
  });

  return (
    <React.Fragment>
      {
        loader && (
          <div className="w-full h-full bg-gray-soft fixed top-0 left-0 z-40">
            <div className="fixed top-1/2 left-1/2 z-50 translate-x-[-50%] translate-y-[-50%]">
              <ClockLoader loading={loader}></ClockLoader>
            </div>
          </div>
        )
      }

      <Container className='w-full'>
        <div className='py-4'>
          <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-hard font-extrabold uppercase">
            Iniciar sesión
          </h2>
        </div>
        {
          authError.error && (
            <Alert
              title={authError.title}
              description={authError.description}
              onClick={ () => setAuthError(emptyAuthErrorType) }
            />
          )
        }

        <form
          id="login-form"
          onSubmit={(e) => handleSubmit(e)}
          className='py-4'
        >
          <div className="mb-3">
            <IconInput
              label="Usuario"
              icon={
                // FIXME: Color hardcoded
                <PersonIcon style={{ color: "#8d43ed" }} />
              }
              name="user"
              type="text"
              onChange={formValidation.handleChange}
              onBlur={formValidation.handleBlur}
              value={formValidation.values.user || ""}
            >
              {
                formValidation.touched.user && formValidation.errors.user && (
                  <LabelValidation className=" !text-sm md:!text-sm ">
                    {formValidation.errors.user}
                  </LabelValidation>
                )
              }
            </IconInput>
          </div>

          <div className="mb-3">
            <div className="input-group auth-pass-inputgroup">
              <IconInput
                label="Contraseña"
                icon={
                  // FIXME: Color hardcoded
                  passwordHide
                    ? <HttpsIcon style={{ color: "#8d43ed" }} />
                    : <VisibilityIcon style={{ color: "#8d43ed" }} />
                }
                name="password"
                type={passwordHide ? "password" : "text"}
                autoComplete="true"
                onChange={formValidation.handleChange}
                onBlur={formValidation.handleBlur}
                value={formValidation.values.password || ""}
                onClick={handlePasswordVisibility}
              >
                {
                  formValidation.touched.password && formValidation.errors.password && (
                    <LabelValidation className=" !text-sm md:!text-sm ">
                      {formValidation.errors.password}
                    </LabelValidation>
                  )
                }
              </IconInput>
            </div>
          </div>

          <div className="mt-5 d-grid">
            <div className='flex items-center justify-center'>
              <RoundedButton
                className="w-full md:w-1/2"
                type="submit"
              >
                Acceder
              </RoundedButton>
            </div>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
}

export default Login;

const emptyAuthErrorType = {
  title: "No pudimos autenticarte",
  description: "Valida tu información",
  error: false
}