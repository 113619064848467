import { BusinessCreditModel } from '../../model/credits/credits-model';
import ColumnContainer from '../generic/grid/column-container';
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import LightText from '../generic/text/light-text';

type CreditDetailProps = {
  creditSelect: BusinessCreditModel;
};

const CreditDetail = ({
  creditSelect,
}: CreditDetailProps) => {

  return (
    <>
      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Mount requested */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Monto solicitado:
          </LightText>
          <LightText className='font-bold'>
            {`$${creditSelect.montoSolicitado.toLocaleString()}`}
          </LightText>
        </ColumnContainer>
        {/* Number of quotas */}
        <ColumnContainer>
        <LightText className='!text-purple-primary '>
          No. Cuotas:
        </LightText>
        <LightText className='font-bold'>
          {creditSelect.numPagos}
        </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>

      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Payment schema */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Esquema de pago:
          </LightText>
          <LightText className='font-bold'>
            {`${creditSelect.esquemaPago.descripcion}`}
          </LightText>
        </ColumnContainer>
        {/* Commission */}
        <ColumnContainer>
        <LightText className='!text-purple-primary '>
          Comisión:
        </LightText>
        <LightText className='font-bold'>
          {`$${creditSelect.comision.toLocaleString()}`}
        </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>

      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Credit type */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Tipo de crédito:
          </LightText>
          <LightText className='font-bold'>
            {`${creditSelect.tipoCredito.descripcion}`}
          </LightText>
        </ColumnContainer>
        {/* Recurring quota */}
        <ColumnContainer>
        <LightText className='!text-purple-primary '>
          Cuota recurrente:
        </LightText>
        <LightText className='font-bold'>
          {`$${creditSelect.cuotaRecurrente.toLocaleString()}`}
        </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>
    </>
  );
};

export default CreditDetail;