import { CREDIT_STATUS } from '../../../utils/constants';

type CreditStatusBadgeProps = {
  status: CREDIT_STATUS;
  className?: string;
};

const CreditStatusBadge = ({
  status, 
  className
}: CreditStatusBadgeProps) => {

  const secondStyle = className ?? "";
  let css = "";
  let creditStatus = "";

  switch(status) { 
    case CREDIT_STATUS.ACTIVE: { 
      creditStatus = "Al día";
      css = " bg-green-primary "
      break; 
    } 
    case CREDIT_STATUS.SETTLED: { 
      creditStatus = "Liquidado";
      css = " bg-purple-primary "
      break; 
    }
    case CREDIT_STATUS.SETTLED_BY_RENEWAL: { 
      creditStatus = "Liquidado por Renovación";
      css = " bg-purple-primary "
      break; 
    }
    case CREDIT_STATUS.EXPIRED: { 
      creditStatus = "Vencido";
      css = " bg-red-primary "
      break; 
    }
    case CREDIT_STATUS.DELAY_3_DAYS: { 
      creditStatus = "Atraso de 3 días";
      css = " bg-orange-primary "
      break; 
    }
    case CREDIT_STATUS.DELAY_MORE_3_DAYS: { 
      creditStatus = "Atraso de más de 3 días";
      css = " bg-red-primary "
      break; 
    }
    case CREDIT_STATUS.DELAY_MORE_10_DAYS: { 
      creditStatus = "Atraso de más de 10 días";
      css = " bg-red-primary "
      break; 
    }
    case CREDIT_STATUS.DELAY_LESS_3_DAYS: { 
      creditStatus = "Atraso de menos de 3 días";
      css = " bg-yellow-primary "
      break; 
    }
    default: { 
      creditStatus = status;
      css = " bg-gray-hard-primary "
      break; 
    } 
  }
  
  return (
    <>
      <span className={`${css} ${secondStyle} text-white text-base md:text-lg lg:text-xl font-barlow mr-2 px-2.5 py-0.5 rounded-full max-w-fit`}>
        {creditStatus}
      </span>
    </>
  );
}

export default CreditStatusBadge;