type SecondTextHeaderProps = {
    children: React.ReactNode;
    className?: string;
};

const SecondTextHeader = ({
    children, 
    className,
}: SecondTextHeaderProps) => {
    const css = className ?? "";
    return (
        <h2 
            className={`${css} text-gray-hard font-lilita text-2xl md:text-3xl lg:text-4xl break-words leading-none`}
        >
            {children}
        </h2>
    );
}

export default SecondTextHeader;