// ENUMS
export const enum CREDIT_STATUS {
  ACTIVE = "A" ,
  SETTLED = "L",
  SETTLED_BY_RENEWAL = "LR",
  EXPIRED =  "V",
  DELAY_3_DAYS = "A3",
  DELAY_MORE_3_DAYS = "AMA3",
  DELAY_MORE_10_DAYS = "AMA10",
  DELAY_LESS_3_DAYS = "AME3"
};

// ROLES
export const ROLE_CLIENT = "ROLE_CLIENT";

// CREDITS TYPE
export const GENERAL_CREDIT = "G";
export const ADITIONAL_CREDIT = "A";