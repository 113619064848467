import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClockLoader } from "react-spinners";
import { ApiModel } from "../model/apis/apis-model";
import { ConstantsRoutes } from '../pages/routes/constants-routes';
import { getApi, getReportApi, postApi, putApi } from '../services/apis-service';
import { refreshTokenRequest } from '../services/auth-service';
import { emptyClient, emptyCredits, emptyToken, emptyUser, useStorage } from './storage-provider';
import CenterContainer from '../components/generic/grid/center-container';

export type ApisContextProps = {
  isLoading: (loading: boolean) => void;
  logout: () => void;
  newRequest: (request: ApiModel) => Promise<void | AxiosResponse<any, any>>;
  refreshToken: () => Promise<void | AxiosResponse<any, any>>;
}

type ApisProviderProps = {
  children: React.ReactNode;
};

const defaultContext: ApisContextProps = {
  logout: () => {},
  isLoading: () => {},
  newRequest: () => new Promise(resolve => resolve()),
  refreshToken: () => new Promise(resolve => resolve()),
};

const ApisContext = createContext<ApisContextProps>(defaultContext);

const ApisProvider = ({
  children
}: ApisProviderProps) => {

  const [loader, setLoader] = useState<boolean>(false);
  const { token, setToken, setClient, setCredits, setUser } = useStorage();
  
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const logout = () => {
    setToken(emptyToken);
    setClient(emptyClient);
    setCredits(emptyCredits);
    setUser(emptyUser);
    navigate(ConstantsRoutes.LOGIN, { 
      replace: true 
    });
  };

  const isLoading = (loading: boolean) => {
    setLoader(loading);
  }

  const newRequest = (
    request: ApiModel
  ): Promise<void | AxiosResponse<any, any>> => {

    setLoader(true);

    if (!token.accessToken) {
      return getAccessToken(request)
        .finally(() => setLoader(false));
    } else {
      return getApiType(token.accessToken, request)
        .catch((error) => {
          if (error.response.status === 401 || !token.accessToken) {
            return getAccessToken(request)
          } else {
            enqueueSnackbar(
              error.message,
              { variant: 'error' }
            );
            throw Error(error);
          }
        })
        .finally(() => setLoader(false));
    }
  }

  const getAccessToken = (
    request: ApiModel
  ): Promise<void | AxiosResponse<any, any>> => {
    return refreshTokenRequest(
      token.refreshToken
    )
    .then((response) => {
      setToken({        
        user: token.user, 
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      })
      return retryRequest(request, response.data.access_token);
    })
    .catch((err) => {
      enqueueSnackbar(
        `${err.response.data.codigo} - ${err.response.data.detalles[0].mensaje}`,
        { variant: 'error' }
      );
      throw Error(err);
    });
  }

  const retryRequest = (
    request: ApiModel,
    newAccessToken: string
  ): Promise<void | AxiosResponse<any, any>> => {
    return getApiType(newAccessToken, request)
      .catch((error) => {
        enqueueSnackbar(
          error.message,
          { variant: 'error' }
        );
        throw Error(error);
      });
  }

  const refreshToken = (): Promise<void | AxiosResponse<any, any>> => {
    return refreshTokenRequest(
      token.refreshToken
    )
    .then((response) => {
      setToken({        
        user: token.user, 
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      })
    })
    .catch((err) => {
      enqueueSnackbar(
        `${err.response.data.codigo} - ${err.response.data.detalles[0].mensaje}`,
        { variant: 'error' }
      );
      throw Error(err);
    });
  }

  const context = ({
    logout,
    isLoading,
    newRequest,
    refreshToken
  });

  return (
    <ApisContext.Provider value={context}>
      <>
        {
          loader && (
            <div className="w-full h-full bg-gray-soft fixed top-0 left-0 z-40">
              <div className="fixed top-1/2 left-1/2 z-50 translate-x-[-50%] translate-y-[-50%]">
                <CenterContainer className="!flex-col">
                  <ClockLoader loading={loader}></ClockLoader>
                  <div className="py-6 flex flex-col items-center justify-center">
                    <p className="font-bold">Cargando información</p>
                    <p>Este proceso puede tardar varios segundos ...</p>
                  </div>
                </CenterContainer>
              </div>
            </div>
          )
        }
        {children}
      </>
    </ApisContext.Provider>
  ); 
}

export const useApis = () => {
  return useContext(ApisContext);
};

export default ApisProvider;

export const getApiType = (
  accessToken: string,
  request: ApiModel,
): Promise<AxiosResponse<any, any>> => {
  switch (request.verb) {
    case "POST":
      return postApi(accessToken, request);
    case "PUT":
      return putApi(accessToken, request);  
    case "GET":
      return getApi(accessToken, request);
    case "REPORT":
      return getReportApi(accessToken, request);
    default:
      return getApi(accessToken, request);
  }
}