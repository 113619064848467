import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";
import BoldText from "../text/bold-text";

type CopyButtonProps = {
  text: string;
};

const CopyButton = ({
  text,
}: CopyButtonProps) => {

  const [copy, setCopied] = useState<boolean>(false);

  const copyText = () => {
    setCopied(true);
    navigator.clipboard.writeText(text)
  }
  
  return (
    <>
    {
      copy 
        ? (
          <button 
            className='bg-green-light rounded-xl p-2 my-2 md:mx-2'
          >
            <div className='flex flex-row justify-between items-center'>
              <BoldText>Texto copiado</BoldText>
              <CheckCircleIcon className='mx-2 text-green-primary'/>
            </div>
          </button>
        )
        : (
          <button 
            className='bg-gray-soft rounded-xl p-2 my-2 md:mx-2 group'
            onClick={copyText}
          >
            <div className='flex flex-row justify-between items-center'>
              <BoldText className='group-hover:text-purple-primary px-2'>
                {text}
              </BoldText>
              <ContentCopyIcon className='mx-4'/>
            </div>
          </button>
        )
    }
    </>
  );
}

export default CopyButton;