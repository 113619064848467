import { LegacyRef } from 'react';
import { BusinessCreditModel } from '../../model/credits/credits-model';
import { CREDIT_STATUS } from '../../utils/constants';
import { isArrayNotEmpty } from '../../utils/validations';
import CreditStatusBadge from '../generic/badge/credit-status-badge';
import RoundedButton from '../generic/buttons/rounded-button';
import CenterContainer from '../generic/grid/center-container';
import ColumnContainer from '../generic/grid/column-container';
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import RoundedRectangle from '../generic/shapes/rounded-rectangle';
import BoldText from '../generic/text/bold-text';
import SecondTextHeader from '../generic/text/second-text-header';
import CreditDetail from './credit-detail';
import CreditPercentage from './credit-percentage';
import QuotaDetail from './quota-detail';

type AllCreditsProps = {
  hookRef: LegacyRef<HTMLDivElement>;
  credits: BusinessCreditModel[];
  creditSelect: BusinessCreditModel | undefined;
  selectCredit: (contractNumber: string) => void;
  generateAccountStatement: (creditSelect: BusinessCreditModel) => void;
};

const AllCredits = ({
  hookRef,
  credits,
  creditSelect,
  selectCredit,
  generateAccountStatement
}: AllCreditsProps) => {

  return (
    <>
    <SpaceBetweenContainer>
      
      <ColumnContainer className='w-full md:w-1/2'>
        <SecondTextHeader className='!text-purple-primary py-4'>
          TODOS MIS CRÉDITOS
        </SecondTextHeader>
        {
          isArrayNotEmpty(credits) 
            ? (
              credits.map((credit) => 
                <CreditPercentage
                  key={credit.numContrato}
                  contractNumber={credit.numContrato}
                  credit={credit}
                  isSelected={credit.numContrato === creditSelect?.numContrato}
                  onClick={selectCredit}
                />
              )
            )
            : (
              <>
                <ColumnContainer>
                  <BoldText>
                    Aún no cuentas con ningún crédito
                  </BoldText>
                </ColumnContainer>
              </>
            )
        }
      </ ColumnContainer>
      {/* Credit detail */}
      {
        creditSelect && (
          <div ref={hookRef} className={`flex flex-col w-full py-4 md:p-0 md:w-5/12`}>
            <div className='relative'>
              {/* Triangle */}
              <div className="h-0 w-0 md:h-16 md:w-16 lg:h-20 lg:w-20 absolute md:left-[-20px] lg:left-[-40px] top-20 rotate-45 transform borde bg-gray-soft z-[-10]" />
              <RoundedRectangle className='!bg-gray-soft'>
                <ColumnContainer>

                  <div className='py-2 lg:px-8 lg:py-4'>
                    {/* Subtitle */}
                    <SecondTextHeader className='!text-purple-primary'>
                      No. de contrato:
                    </SecondTextHeader>
                    <BoldText>{creditSelect.numContrato}</BoldText>
                  </div>

                  <div className='py-2 lg:px-8 lg:py-4'>
                    {/* Divisor */}
                    <CenterContainer>
                      <div className="h-1 w-11/12 absolute bg-purple-primary"></div>
                    </CenterContainer>
                    {/* Subtitle */}
                    <SecondTextHeader className='!text-purple-primary py-4'>
                      Última cuota:
                    </SecondTextHeader>
                    {/* Quota selected */}
                    <QuotaDetail creditSelect={creditSelect} />
                  </div>

                  <div className='py-2 lg:px-8 lg:py-4'>
                    {/* Divisor */}
                    <CenterContainer>
                      <div className="h-1 w-11/12 absolute bg-purple-primary"></div>
                    </CenterContainer>
                    {/* Subtitle */}
                    <div className='py-4'>
                      <SecondTextHeader className='!text-purple-primary py-2'>
                        Datos del crédito:
                      </SecondTextHeader>
                      <CreditStatusBadge 
                        status={creditSelect.estatus.clave as CREDIT_STATUS} // FIXME:
                      />
                    </div>
                    {/* Credit detail */}
                    <CreditDetail creditSelect={creditSelect} />
                  </div>

                  {/* Account stament */}
                  <CenterContainer className='py-4 lg:py-8'>
                    <RoundedButton
                      onClick={() => generateAccountStatement(creditSelect)}
                    >
                      Descargar estado de cuenta
                    </RoundedButton>
                  </CenterContainer>

                </ColumnContainer> 
              </RoundedRectangle>
            </div>
          </div>
        )
      }
    </SpaceBetweenContainer>
    </>
  );
};

export default AllCredits;