import { ApiModel } from "../model/apis/apis-model";
import { Config } from '../utils/environment-constants';

const CREDITS_PATH = '/creditos/v1';

export const mapGetBusinessCredits = (
  businessId: string
):ApiModel => ({
  endpoint: `${Config.API.URL}${CREDITS_PATH}/portal/creditos/negocio?numNegocio=${businessId}`,
  verb: "GET"
});