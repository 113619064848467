import React from "react";

type RoundedRectagleProps = {
  children?: React.ReactNode;
  className?: string;
}

const RoundedRectangle = ({ 
  children, 
  className 
}: RoundedRectagleProps) => {

  const style = className ?? "" 

  return (
    <div className={ ' flex items-center rounded-3xl bg-purple-primary p-4 ' + style }>
      {children}
    </div>
  );
}

export default RoundedRectangle
