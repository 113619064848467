import { ApiModel } from "../model/apis/apis-model";
import { Config } from '../utils/environment-constants';

const REPORTS_PATH = '/reportes/v1';

export const mapGetStatmentAccount = (
  contractNumber: string
):ApiModel => ({
  endpoint: `${Config.API.URL}${REPORTS_PATH}/estado-cuenta?numContrato=${contractNumber}`,
  verb: "REPORT"
});