import axios from "axios";
import { ApiModel } from "../model/apis/apis-model";
import { TokenModel } from "../model/auth/token-model";
import { UpdatePasswordModel } from "../model/users/user-model";
import { Config } from '../utils/environment-constants';

const USER_PATH = '/usuarios/v1';

// Only used for login
// Provider scope no allows useAuth hokk
export const getUserRequestLogin = async (
  tokenModel: TokenModel,
  user: string
) => {
  return axios.get(
    `${Config.API.URL}${USER_PATH}/usuario/info-general?codigoUsuario=${user}`,
    {
      headers: {
        'Authorization': `Bearer ${tokenModel.accessToken}`,
      },
    }
  );
}

// ------------------------------------------------------
// Use Auth hook allowed
// ------------------------------------------------------

// GET
export const getUserRequest = (
  user: string
):ApiModel => ({
  endpoint: `${Config.API.URL}${USER_PATH}/usuario/info-general?codigoUsuario=${user}`,
  verb: "GET"
});

// PUT
export const updatePassword = (
  passwordUpdated: UpdatePasswordModel,
):ApiModel => ({
  endpoint: `${Config.API.URL}${USER_PATH}/password`,
  verb: "PUT",
  body: JSON.stringify(passwordUpdated)
});
