import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedPassword from '../../components/auth/protected-password';
import ClientsPortal from '../../components/clients/portal';
import UpdatePasswordForm from '../../components/form/update-password-form';
import { ConstantsRoutes } from './constants-routes';

const RoutesPages = () => {
  return (
    <>
      <Routes>
        <Route 
          path={ConstantsRoutes.UPDATE_DATA} 
          element={<UpdatePasswordForm/>} 
        />
        <Route 
          path={ConstantsRoutes.HOME} 
          element={
            <ProtectedPassword>
              <ClientsPortal/>
            </ProtectedPassword>
          } 
        />
        <Route 
          path={ConstantsRoutes.ANY} 
          element={
            <Navigate to={ConstantsRoutes.HOME} replace />
          } 
        />
      </Routes>
    </>
  )
}

export default RoutesPages
