import { Container, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useApis } from "../../../providers/apis-provider";
import RoundedButton from '../../generic/buttons/rounded-button';
import BoldText from "../../generic/text/bold-text";
import LightText from "../../generic/text/light-text";
import SecondTextHeader from "../../generic/text/second-text-header";

const TIME_LEFT_SEG = 30 // 30 seg
const TIME_LEFT_MS = 30000 // 30 seg
const TIME_TOKEN = 540000 // 9 min

const TimeoutModal = () => {

  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const { logout, refreshToken } = useApis();

  const createWarning = () => setTimeout(() => { 
    setWarningModalOpen(true);
    setTimeLeft(TIME_LEFT_SEG)
  }, TIME_TOKEN)

  const createLogout = () => setTimeout(() => {
    logout();
  }, TIME_LEFT_MS)

  const keepSesionAlive = () => {
    setWarningModalOpen(false);
    refreshToken()
    .then(() => createWarning())
    .catch(() => logout());
  }

  // TODO: Read expiration time token,
  useEffect(
    () => {
      // Initialization
      let timeout = isWarningModalOpen 
        ? createLogout() 
        : createWarning()

      // Cleanup
      return () => clearTimeout(timeout);
    },
    // eslint-disable-next-line
    [isWarningModalOpen]
  );

  useEffect(() => {

    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  
  return (
    <div>
    {
      isWarningModalOpen && (
        <Modal
          open={isWarningModalOpen}
          onClose={(event, reason) => {
            if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              setWarningModalOpen(false);
            }
          }
        }
        >
          <>
          <Container className='bg-white !max-w-none !text-start py-8'>
            {/* Sesión esta por terminar */}
            <SecondTextHeader className='!text-purple-primary py-4'>
              Sesión por expirar
            </SecondTextHeader>
            <BoldText> ¿Aún sigues conectado?</BoldText>
            <LightText>
              {`, si no das click en botón de abajo tu sesión se cerrará en `}
            </LightText>
            <BoldText> 
              {`${timeLeft} segundos`}
            </BoldText>
            <br />
            <RoundedButton 
              className="my-4"
              onClick={keepSesionAlive}
            >
              Mantener sesión abierta
            </RoundedButton>
            <br />
          </Container>
          </>
        </Modal>
      )
    }
    </div>
  ) 
}

export default TimeoutModal;