type CenterContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const CenterContainer = ({
  children, 
  className,
}: CenterContainerProps) => {
  const css = className ?? "";
  return (
    <div className={`${css} flex justify-center items-center`}>
      {children}
    </div>
  );
}

export default CenterContainer;