import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/local-storage";
import { TokenModel, UserModel } from "../model/auth/token-model";
import { ClientBusinessModel } from "../model/clients/clients-model";
import { BusinessCreditModel } from "../model/credits/credits-model";

export type StorageContextProps = {
  token: TokenModel;
  setToken: React.Dispatch<React.SetStateAction<TokenModel>>;
  user: UserModel;
  setUser: React.Dispatch<React.SetStateAction<UserModel>>;
  client: ClientBusinessModel;
  setClient: React.Dispatch<React.SetStateAction<ClientBusinessModel>>;
  credits: BusinessCreditModel[];
  setCredits: React.Dispatch<React.SetStateAction<BusinessCreditModel[]>>;
}

type StorageProviderProps = {
  children: React.ReactNode;
};

export const emptyToken: TokenModel = {
  accessToken: "",
  refreshToken: "",
  user: "",
}

export const emptyUser: UserModel = {
  userId: 0,
  user: "",
  name: "",
  actualizado: false,
  bloqueo: false
}

export const emptyClient: ClientBusinessModel = {
  idNegocio: 0,
  numNegocio: "",
  nombre: "",
  telefonos: [],
  cliente: {
    idCliente: 0,
    numCliente: "",
    persona: {
      idPersona: 0,
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: ""
    }
  }
}

export const emptyCredits: BusinessCreditModel[] = []

const defaultContext: StorageContextProps = {
  token: emptyToken,
  setToken: () => {},
  user: emptyUser,
  setUser: () => {},
  client: emptyClient,
  setClient: () => {},
  credits: emptyCredits,
  setCredits: () => {}
};

const StorageContext = createContext<StorageContextProps>(defaultContext);

const StorageProvider = ({
  children
}: StorageProviderProps) => {

  const [token, setToken] = useLocalStorage<TokenModel>("auth", emptyToken);
  const [user, setUser] = useLocalStorage<UserModel>("user", emptyUser);
  const [client, setClient] = useLocalStorage<ClientBusinessModel>("client", emptyClient);
  const [credits, setCredits] = useLocalStorage<BusinessCreditModel[]>("credits", emptyCredits);

  const context = ({
    token,
    setToken,
    user,
    setUser,
    client,
    setClient,
    credits,
    setCredits
  });

  return (
    <StorageContext.Provider value={context}>
      {children}
    </StorageContext.Provider>
  ); 
}

export const useStorage = () => {
  return useContext(StorageContext);
};

export default StorageProvider;