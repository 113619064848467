import { useLayoutEffect, useState } from "react";

type WindowSizeProps = {
  width: number;
  height: number;
}

const useWindowSize = () => {

  const [size, setSize] = useState(emptyWindowSize);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({
        width: window.innerWidth, 
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}  

export default useWindowSize;

const emptyWindowSize:WindowSizeProps = {
  height: 0,
  width: 0
}