type ColumnContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const ColumnContainer = ({
  children, 
  className,
}: ColumnContainerProps) => {
  const css = className ?? "";
  return (
    <div className={`${css} flex flex-col w-full`}>
      {children}
    </div>
  );
}

export default ColumnContainer;