
type HeaderProps = {
    children: React.ReactNode;
    className?: string;
};

const Header = ({
    children,
    className,
}: HeaderProps) => {

    const css = className ?? "";
    return (
        <header 
            className={`flex justify-between items-center max-w-screen-xl mx-auto ${css}`}
        >
            {children}
        </header>
    );
};

export default Header;