import { Fragment, useState } from 'react';
import { BusinessCreditModel } from '../../model/credits/credits-model';
import { CREDIT_STATUS } from '../../utils/constants';
import { isArrayNotEmpty } from '../../utils/validations';
import CreditStatusBadge from '../generic/badge/credit-status-badge';
import ColumnContainer from '../generic/grid/column-container';
import RowContainer from '../generic/grid/row-container';
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import RoundedRectangle from '../generic/shapes/rounded-rectangle';
import BoldText from '../generic/text/bold-text';
import LightText from '../generic/text/light-text';
import SecondTextHeader from '../generic/text/second-text-header';
import PaymentsMethodsModal from '../shared/modals/payments-methods-modal';

type ActiveCreditsProps = {
  creditsActive: BusinessCreditModel[];
};

const ActiveCredits = ({
  creditsActive,
}: ActiveCreditsProps) => {

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
    {/* Modal  */}
    <PaymentsMethodsModal  
      open={open}
      setOpen={setOpen}
    />

    <div className='py-8 w-full'>
    {
      <RoundedRectangle className='!bg-gray-soft'>
        <ColumnContainer className='lg:px-6'>

          <SpaceBetweenContainer className='items-center'>
            {/* Title */}
            <SecondTextHeader className='!text-purple-primary md:py-4'>
              CRÉDITOS ACTIVOS
            </SecondTextHeader>
            {/* Help button */}
            <button 
              className='w-fit p-2 my-2 md:my-0 rounded-full hover:bg-purple-primary bg-gray-hard font-extrabold font-barlow text-white text-center text-base md:text-xl lg:text-2xl'
              onClick={() => setOpen(true)}
            > 
              ¿Cómo pagar? 
            </button>
          </SpaceBetweenContainer>

          <SpaceBetweenContainer>
          {
            isArrayNotEmpty(creditsActive) 
              ? ( creditsActive.map( (activeCredit) => 
                <Fragment key={activeCredit.numContrato}>
                  <ColumnContainer>
                    <BoldText>
                      { activeCredit.tipoCredito.descripcion.toUpperCase() }
                    </BoldText>
                    <RowContainer className='py-2'>
                      <CreditStatusBadge 
                        status={activeCredit.estatus.clave as CREDIT_STATUS} // FIXME:
                      />
                      <LightText>
                        {activeCredit.numContrato}
                      </LightText>
                    </RowContainer>
                  </ColumnContainer>
                </Fragment>
              ))
              : (
                <>
                  <ColumnContainer>
                    <BoldText>
                      Sin créditos activos
                    </BoldText>
                  </ColumnContainer>
                </>
              )
          }
          </SpaceBetweenContainer>

        </ColumnContainer>
      </RoundedRectangle>
    }
    </div>
    </>
  );
};

export default ActiveCredits;