import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';

interface IconInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  ref?: string;
  label: string;
  icon: any; // FIXME: Data type
  children?: React.ReactNode;
  onClick?: () => void;
}

const IconInput: ForwardRefRenderFunction<HTMLInputElement, IconInputProps> = ({
  name,
  ref,
  icon,
  label,
  children,
  onClick,
  ...otherProps
}: IconInputProps) => {
  return (
    <>
      <div className='w-full relative'>
        <button 
          type='button'
          className='w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3' 
          onClick={onClick}
        >
          {icon}
        </button>
        <input 
          {...otherProps}
          id={name}
          name={name}
          ref={ref} 
          placeholder={label}
          className={
            children
                ? `form-input rounded-md border border-red-primary bg-transparent py-3 px-4 text-red-primary appearance-none w-full block pl-14 focus-visible:outline-red-primary`
                : `form-input rounded-md border border-gray-hard bg-transparent py-3 px-4 appearance-none w-full block pl-14 focus-visible:outline-purple-primary`
          }
        />
      </div>
      {children}
    </>
  );
};

export default IconInput;