import axios from "axios";
import { ApiModel } from "../model/apis/apis-model";

export const postApi = async (
  accessToken: string,
  api: ApiModel,
) => {
  return axios.post(
    api.endpoint,
    api.body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      }
    }
  );
}

export const putApi = async (
  accessToken: string,
  api: ApiModel,
) => {
  return axios.put(
    api.endpoint,
    api.body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      }
    }
  );
}

export const getApi = async (
  accessToken: string,
  api: ApiModel,
) => {
  return axios.get(
    api.endpoint,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  );
}

export const getReportApi = async (
  accessToken: string,
  api: ApiModel,
) => {
  return axios.get(
    api.endpoint,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      responseType: 'blob'
    }
  );
}