import { Autocomplete } from '@mui/material';
import { AutocompleteOptions } from '../../../model/inputs/autocomplete-model';

interface SearchInputProps {
  name?: string;
  text: string;
  icon: any; // FIXME: Data type
  options: AutocompleteOptions[];
  onClick: (contractNumber: string) => void;
}

const SearchInput = ({
  name,
  icon,
  text,
  options,
  onClick
}: SearchInputProps) => {

  return (
    <>
      <Autocomplete
        disablePortal
        id={name}
        options={options}
        sx={{ maxWidth: "350px", minWidth:"250px", width:"100%" }}
        onChange={(event, value) => {
          onClick(value?.label ?? "")
        }} // TODO: Undefined validation
        renderInput={
          (params) => 
          <div 
            className='w-full relative my-4 md:my-0'
            ref={params.InputProps.ref}
          >
            <button 
              type='button'
              className='w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3'
            >
              {icon}
            </button>
            <input 
              {...params.inputProps}
              className={`form-input rounded-full border border-gray-hard bg-transparent py-3 px-4 appearance-none w-full block pl-14 focus-visible:outline-purple-primary`}
              id={name}
              name={name} 
              placeholder={text}
            />
          </div>
        }
      />
    </>
  );
};

export default SearchInput;