import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/pointmx.svg";
import userImage from "../../../assets/images/user-profile.png";
import { ConstantsRoutes } from '../../../pages/routes/constants-routes';
import { useApis } from "../../../providers/apis-provider";
import { useStorage } from "../../../providers/storage-provider";

const Navbar = () => {

  const [toggleprofile, setToggleProfile] = useState<boolean>(false);
  const [menuButton, setMenuButton] = useState<boolean>(false);
  
  const { user } = useStorage();
  const { logout } = useApis();
  const navigate = useNavigate();

  return (
    <>
      <nav className="bg-white">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-20">

            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <div onClick={() => setMenuButton(!menuButton)}>
                <button id="togglemebutton" className="inline-flex items-center justify-center p-2 text-gray-hard hover:text-purple-primary focus:outline-none transition duration-150 ease-in-out" aria-label="Main menu"
                  aria-expanded="false">
                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div 
                className="flex-shrink-0 hover:cursor-pointer" 
                onClick={() => navigate(ConstantsRoutes.HOME)}
              >
                <img className="block lg:hidden h-12 w-auto" src={logo} alt="logo" />
                <img className="hidden lg:block h-12 w-auto" src={logo} alt="logo" />
              </div>
            </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* Profile dropdown */}
              <div className="hidden sm:block sm:ml-6">
                <div className="flex">
                  <div className="px-3 py-2 text-xl font-medium leading-5 text-gray-hard">{user.name}</div>
                </div>
              </div>
              <div className="ml-3 relative">
                <div onClick={() => setToggleProfile(!toggleprofile)}>
                  <button id="toggleprofile" className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-blue-soft transition duration-150 ease-in-out" aria-label="User menu" aria-haspopup="true">
                    <img className="h-11 w-11 rounded-none" src={userImage} alt="" />
                  </button>
                </div>
                {/* Profile dropdown panel, show/hide based on dropdown state. */}
                {
                toggleprofile && (
                  <div id="resultprofile" className="z-40 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg">
                    <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                      <div className="cursor-pointer">
                        <div 
                          className="block px-4 py-2 text-base leading-5 text-gray-hard hover:text-purple-primary focus:outline-none focus:text-purple-primary transition duration-150 ease-in-out" 
                          role="menuitem"
                          onClick={
                            () => {
                              navigate(ConstantsRoutes.UPDATE_DATA)
                              setToggleProfile(!toggleprofile)
                            }
                          } 
                        >
                          Actualizar contraseña
                        </div>
                        <div 
                          className="block px-4 py-2 text-base leading-5 text-gray-hard hover:text-purple-primary focus:outline-none focus:text-purple-primary transition duration-150 ease-in-out" 
                          role="menuitem"
                          onClick={
                            () => {
                              logout()
                              setToggleProfile(!toggleprofile)
                            }
                          }
                        >
                          Cerrar Sesión
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu, toggle classes based on menu state. */}
        {
          // TODO: Agregar funcionalidades para celular
          menuButton && (<></>)
        }
      </nav>
    </>
  );
};

export default Navbar;