import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './components/auth/protected-route';
import ClientsLayout from './components/shared/layout/clients-layout';
import Layout from './components/shared/layout/layout';
import LoginPage from './pages/login-page';
import { ConstantsRoutes } from './pages/routes/constants-routes';
import RoutesMyAccountPages from './pages/routes/routes-pages';
import ApisProvider from './providers/apis-provider';
import StorageProvider from './providers/storage-provider';

function App() {
  return (
    <StorageProvider>
      <SnackbarProvider 
        variant="success"
        autoHideDuration={3000}
      >
        <BrowserRouter>
          <ApisProvider>
            <Routes>
              <Route 
                path={ConstantsRoutes.LOGIN}
                element={
                  <Layout>
                    <LoginPage/>
                  </Layout>
                }
              />
              <Route 
                path={ConstantsRoutes.ANY} 
                element={ 
                  <ProtectedRoute>
                    <ClientsLayout>
                      <RoutesMyAccountPages />
                    </ClientsLayout>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </ApisProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </StorageProvider>
  );
}

export default App;
