type SpaceBetweenContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const SpaceBetweenContainer = ({
  children, 
  className,
}: SpaceBetweenContainerProps) => {
  const css = className ?? "";
  return (
    <div className={`${css} flex flex-col md:flex-row justify-between`}>
      {children}
    </div>
  );
}

export default SpaceBetweenContainer;