import { Container, Modal } from "@mui/material";
import CopyButton from "../../generic/buttons/copy-button";
import ColumnContainer from "../../generic/grid/column-container";
import BoldText from "../../generic/text/bold-text";
import LightText from "../../generic/text/light-text";
import SecondTextHeader from "../../generic/text/second-text-header";

type PaymentsMethodsModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentsMethodsModal = ({
  open,
  setOpen
}: PaymentsMethodsModalProps) => {
  return (
    <div>
    {
      open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
        >
          <>
          <Container className='bg-white !max-w-none !text-start'>

            {/* Formas de pago */}
            <SecondTextHeader className='!text-purple-primary py-4'>
              Formas de pago
            </SecondTextHeader>
            <LightText>
              ⮕ Recuerda que nuestro cobrador visitará tu negocio (dependiendo tu periodicidad de pago).
            </LightText>
            <br />
            <LightText>
              ⮕ Ahora también puedes hacer pagos por medio de transferencia bancaria. Es importante que no olvides mandarnos tu comprobante a nuestro
            </LightText>
            <BoldText> WhatsApp</BoldText>
            <br />

            {/* Datos bancarios */}
            <ColumnContainer className='!w-fit my-4'>
              <SecondTextHeader className='!text-purple-primary py-4'>
                Datos bancarios
              </SecondTextHeader>
              <div className='flex flex-col md:flex-row justify-start md:items-center'>
                <BoldText> {`Banco: `} </BoldText>
                <LightText className='my-2 md:mx-2'> BBVA </LightText>
              </div>
              <div className='flex flex-col md:flex-row justify-start md:items-center'>
                <BoldText> {`Nombre: `} </BoldText>
                <LightText className='my-2 md:mx-2'> FINANTIAL RICARTPOINT SAPI DE CV SOFOM ENR </LightText>
              </div>
              <div className='flex flex-col md:flex-row justify-start md:items-center'>
                <BoldText> No. de cuenta: </BoldText>
                <CopyButton text='012 004 4350' />
              </div>
              <div className='flex flex-col md:flex-row justify-start md:items-center'>
                <BoldText> Clabe: </BoldText>
                <CopyButton text='012 290 00120044350 7' />
              </div>
            </ColumnContainer>
          </Container>
          </>
        </Modal>
      )
    }
    </div>
  ) 
}

export default PaymentsMethodsModal;
