import axios from "axios";
import { encodeStringToBase64 } from "../utils/encoders";
import { Config } from '../utils/environment-constants';

export type authUserType = {
  username: string;
  password: string;
}

export const authUserRequest = async ({
  username,
  password
}: authUserType) => {

  const data = `username=${username}&password=${encodeStringToBase64(password)}&grant_type=password`

  return axios.post(
    `${Config.API.URL}/security/oauth/token`,
    data,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: Config.API.USER_NAME,
        password: Config.API.PASSWORD
      }
    }
  );
}

export const refreshTokenRequest = async (
  refreshToken: string
) => {

  const data = `refresh_token=${refreshToken}&grant_type=refresh_token`

  return axios.post(
    `${Config.API.URL}/security/oauth/token`,
    data,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: Config.API.USER_NAME,
        password: Config.API.PASSWORD
      }
    }
  );
}