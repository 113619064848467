export const notNullUndefined = <TValue> (value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
}

export const isNullUndefined = <TValue> (value: TValue | null | undefined): value is TValue => {
  return value === null || value === undefined;
}

export const isArrayNotEmpty = (array: any[]): boolean => {
  return  notNullUndefined(array) &&Array.isArray(array) && array.length > 0;
}

export const isArrayEmpty = (array: any[]): boolean => {
  return array?.length <= 0;
}