import { BusinessCreditModel } from '../../model/credits/credits-model';
import { isArrayNotEmpty } from '../../utils/validations';
import ColumnContainer from '../generic/grid/column-container';
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import LightText from '../generic/text/light-text';

type QuotaDetailProps = {
  creditSelect: BusinessCreditModel;
};

const QuotaDetail = ({
  creditSelect,
}: QuotaDetailProps) => {

  return (
    <>
      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Payment required  */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Pago requerido:
          </LightText>
          <LightText className='font-bold'>
            {`$${creditSelect.ultCuota.pagoRequerido.toLocaleString()}`}
          </LightText>
        </ColumnContainer>
        {/* Quota number */}
        <ColumnContainer>
        <LightText className='!text-purple-primary '>
          No. Cuota:
        </LightText>
        <LightText className='font-bold'>
          {creditSelect.ultCuota.numCuota}
        </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>

      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Deadline  */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Fecha limite pago:
          </LightText>
          <LightText className='font-bold'>
            {creditSelect.ultCuota.fechaFinCuota}
          </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>

      <SpaceBetweenContainer className='lg:py-4 md:!flex-col lg:!flex-row'>
        {/* Amount charged */}
        <ColumnContainer>
          <LightText className='!text-purple-primary'>
            Monto Cobrado:
          </LightText>
          <LightText className='font-bold'>
          {
            isArrayNotEmpty(creditSelect.ultCuota.pagos) 
              ? (`$${creditSelect.ultCuota.pagos[0].montoCobrado.toLocaleString()}`) 
              : (`$0`)
          }
          </LightText>
        </ColumnContainer>
        {/* Payment date */}
        <ColumnContainer>
          <LightText className='!text-purple-primary '>
            Fecha de pago:
          </LightText>
          <LightText className='font-bold'>
          {
            isArrayNotEmpty(creditSelect.ultCuota.pagos) 
              ? ( creditSelect.ultCuota.pagos[0].fechaPago )
              : (`Sin pago`)
          }
          </LightText>
        </ColumnContainer>
      </SpaceBetweenContainer>
    </>
  );
};

export default QuotaDetail;