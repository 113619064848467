import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../hooks/window-size';
import { BusinessCreditModel } from '../../model/credits/credits-model';
import { AutocompleteOptions } from '../../model/inputs/autocomplete-model';
import { useApis } from '../../providers/apis-provider';
import { useStorage } from '../../providers/storage-provider';
import { mapGetUserInfo } from '../../services/clients-service';
import { mapGetBusinessCredits } from '../../services/credits-service';
import { mapGetStatmentAccount } from '../../services/reports-service';
import { isArrayNotEmpty } from '../../utils/validations';
import AllCredits from '../credits/all-credits';
import ActiveCredits from '../credits/credits-active';
import ColumnContainer from '../generic/grid/column-container';
import SpaceBetweenContainer from '../generic/grid/space-between-container';
import SearchInput from '../generic/inputs/search-input';
import TextHeader from "../generic/text/text-header";
import TimeoutModal from '../shared/modals/timeout-modal';

const ClientsPortal = () => {

  const [creditSelect, setCreditSelect] = useState<BusinessCreditModel | undefined>(undefined);
  const [creditsActive, setCreditsActive] = useState<BusinessCreditModel[]>([]);
  const [options, setOptions] = useState<AutocompleteOptions[]>([]);
  const [padding, setPadding] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  
  const { newRequest } = useApis();
  const { width } = useWindowSize();
  const { user, client, setClient, credits, setCredits } = useStorage();

  const selectCredit = (contractNumber: string) => {
    const creditFind = credits.find(
      (credit) => credit.numContrato === contractNumber
    )
    setCreditSelect(creditFind);
    setPadding( // TODO: improve margin calculated
      credits.findIndex( (credit) => credit.numContrato === creditFind?.numContrato )
    )
  }

  const generateAccountStatement = (credit: BusinessCreditModel) => {
    newRequest(
      mapGetStatmentAccount(credit.numContrato)
    )
    .then((response: any) => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const  downloadURL = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.setAttribute('target', '_blank');
      link.click();
    });
  }

  useEffect(
    () => {
      if(client?.idNegocio === 0) {
        // Get business by user
        newRequest(
          mapGetUserInfo(user.user)
        )
        .then(
          (responseClients: any) => {
            setClient(responseClients.data.resultado)
            // Get business credits
            newRequest(
              mapGetBusinessCredits(responseClients.data.resultado[0].numNegocio) // FIXME: Response can be a list of business
            )
            .then((responseCredits: any) => {
              // Sort by fecha de autorización
              const creditsSorted = (responseCredits.data.resultados as Array<BusinessCreditModel>)
              .sort( (prev, act) => 
                new Date(act.fechaApertura).getTime() - new Date(prev.fechaApertura).getTime()
              );
              // Set Credits
              setCredits( creditsSorted )
              // Set Options for search button
              setOptions(
                credits.map(
                  (credit): AutocompleteOptions => ({
                    label: credit.numContrato,
                    id: credit.idCredito.toString()
                  })
                )
              );
              // Set credits active
              setCreditsActive(
                credits.filter(
                  (credit)  => credit.creditoActivo
                )
              );
            })
          }
        );
      }
    // eslint-disable-next-line
    }, []
  );

  useEffect(
    () => {
      if(isArrayNotEmpty(credits)) {
        setOptions(
          credits.map(
            (credit): AutocompleteOptions => ({
              label: credit.numContrato,
              id: credit.idCredito.toString()
            })
          )
        );
        setCreditsActive(
          credits.filter(
            (credit)  => credit.creditoActivo
          )
        );
      }
    // eslint-disable-next-line
    }, [credits]
  );

  // FIXME: Calcular margin
  useEffect(() => {
    if(ref.current) {
      if(width > 767) {
        ref.current.style.marginTop = `${padding * 90}px`
      } else {
        ref.current.style.marginTop = `0px`
      }
    }
  // eslint-disable-next-line
  }, [padding] );

  return (
    <>
      {/* Timeout Modal */}
      <TimeoutModal />
      
      <ColumnContainer>
        <SpaceBetweenContainer className='items-center'>
        {
          isArrayNotEmpty(options) && (
          <>
            {/* Left */}
            <TextHeader className="!text-purple-primary">
              MIS CRÉDITOS  
            </ TextHeader>
            {/* Right */}
            <SearchInput
              icon={<SearchIcon />}
              options={options}
              text="Buscar"
              onClick={selectCredit}
            />
          </>
          )
        }
        </SpaceBetweenContainer>

        {/* Active credits */}
        <ActiveCredits creditsActive={creditsActive} />

        {/* All the credits */}
        <AllCredits 
          hookRef={ref}
          creditSelect={creditSelect}
          credits={credits}
          generateAccountStatement={generateAccountStatement}
          selectCredit={selectCredit}
        />
      </ColumnContainer>
    </>
  );
};

export default ClientsPortal;